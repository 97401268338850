const __request = require(`../__request/__request_contentType_form`);

// 国际机票航空公司
export default (data) => {
let pParameter = {
    method: 'post',
    urlSuffix: '/flight/international/citys',
    data:data
  }

  return __request(pParameter)
}

