const __request = require(`../__request/__request_contentType_form`);

// 国际机票支付
export default (data) => {
let pParameter = {
    method: 'post',
    urlSuffix: '/flight/international/buyer/white/order/pay',
    data:data
  }

  return __request(pParameter)
}

