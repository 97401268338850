//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// 订单查询
import detail from "@/lib/data-service/flight/fligthInternationalAdmin/flight_international_buyer_manage_order_detail.js";
import scrollTop from '@/lib/common-service/scrollTop'
import invalid from "@/lib/data-service/flight/fligthInternationalAdmin/flight_international_buyer_white_order_invalid.js";
import refund from "@/lib/data-service/flight/fligthInternationalAdmin/flight_international_buyer_white_order_refund.js";
import findChangeOrder from "@/lib/data-service/flight/fligthInternationalAdmin/flight_inter_buyer_manage_order_findChangeOrder.js";
import {internationalCity,findPkfareChangeReshop,pkfareChangeRequest} from '@/lib/data-service/flight/fligthInternational'
import findPkChangeOrder from "@/lib/data-service/flight/fligthInternationalAdmin/flight_inter_buyer_manage_order_findPkfareChangeOrder";

import  orderChange from '@/lib/data-service/flight/fligthInternationalAdmin/flight_international_buyer_white_order_change'

export default {
  data() {
    return {
      detailData: null,
      applyStatus:{show:false},
      orderNo: "",
      orderStatus: "",
      flightType: "1",
      passengers: [],
      checkList: [],
      trunkShow:null,
      clauseShow:null,
      arrowsInfoShow:[],
      type:'',
      checkPeopleList:[],
      checkFlight:[],
      remark:'',
      formData:{},
      internationalAir:[],
      ticketFlight:[],
      ticketPassenger:[],
      passengeList:[],
      handleTicketBtn:false,
      supplierType:1,
      fligthPeople:{
        adults:0,
        children:0
      },
      loading:false,
      searchFlight:{
        currentPage:1,
        pageSize:5,
        totalCount:0
      },
      changeFligthList:[],
      image_url_airline_blank_map:`https://lyn.oss-cn-shenzhen.aliyuncs.com/temp/airline-blank-map.jpg`,
    };
  },
  methods: {
    handleSelectionChange(val){
        this.checkFlight = val
        let type = this.$route.query.type
        let poepleList = this.detailData.passengeList
        this.passengeList = []
        val.length==0?this.handleTicketBtn = false:this.handleTicketBtn = true
        this.ticketFlight = JSON.parse(JSON.stringify(this.checkFlight))
        if(type=='Changing'){
          poepleList.map(item=>{
           item.showStatus = 0
          })
          val.map(list=>{
              poepleList.map(item=>{
                if(item.segment.indexOf(list.serialNumber)==-1){
                  item.showStatus++
                }else{
                  item.show = true
                }
              })
          })
          poepleList.map(item=>{
            item.showStatus>0?item.show = false:''
          })
        }
        setTimeout(()=>{
          this.passengeList = this.detailData.passengeList
        },100)
    },
    // 日期选择
    setTime(startDate) {
      return {
        disabledDate: time => {
            if(startDate){
              return time.getTime() < this.$moment(startDate)._d.valueOf()
            }else{
               return time.getTime() < Date.now() - 1000 * 3600 * 24
            }
        }
      };
    },
    applyAir(value){
       this.applyStatus.show = true
       this.applyStatus.value = value
    },
    checkboxInit(row,index){
       if(!row.show){
         return false
       }else{
          return true
       }
    },
    handleTicket(val){
       this.ticketPassenger = val
       let adults = 0,children = 0
       this.ticketPassenger.map(item=>{
          //  console.log(item)
           item.psgType==1?adults++:item.psgType==2?children++:''
       })
       this.fligthPeople = {adults,children}
    },
    checkPeople(val,statu){
      statu?
      this.checkPeopleList.push(val):queryList(this.checkPeopleList)
      function queryList(list){
          list.map((item,index)=>{
             if(item.psgId==val.psgId){
                 list.splice(index,1)
             }
          })
      }
    },
    querySearchAsync(queryString,cb){
      var restaurants = this.internationalAir
      var results = queryString
        ? restaurants.filter(filtrate(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results)
      function filtrate(list){
        return (restaurant) => {
        if(restaurant.spell.toUpperCase()===list.toUpperCase()){
            restaurant.value = restaurant.name
            return restaurant
        }else if(restaurant.name.indexOf(list) != -1){
          restaurant.value = restaurant.name
            return restaurant
        }else if(restaurant.code.indexOf(list.toUpperCase()) !== -1){
          restaurant.value = restaurant.name
            return restaurant
        }
      }
      }
    },
    handleCurrentChange(val){
       this.searchFlight.currentPage = val
       this.getInternationalList()
    },
    getInternationalList(){
      if(this.ticketFlight.length==0)return this.$message({type:"warning",message:"请选择改签航班！"})
      for(let i in this.ticketFlight){
        if(!this.ticketFlight[i].beginDate){
          return this.$message({type:"warning",message:"请选择起飞时间！"})
        }
      }
      if(this.fligthPeople.adults==0)return this.$message({type:"warning",message:"请选择改签乘客！"})
      let fligthArr = []
      fligthArr = this.ticketFlight.map(item=>{
         let fligth = {
            "cabinClass": item.cabinRanks,
            "departureDate": item.beginDate,
            "destination": item.eAirportCodes,
            "origin": item.sAirportCodes
         }
        return fligth
      })
      let params = {
          "adults": this.fligthPeople.adults,
          "children":  this.fligthPeople.children,
          "currentPage": this.searchFlight.currentPage,
          "orderNo": this.orderNo,
          "pageSize": this.searchFlight.pageSize,
          "segmentList":fligthArr
        }
      this.loading = true
      findPkfareChangeReshop(params).then(res=>{
         this.arrowsInfoShow = res.pageResult.pageData.map((item,index)=>{
            return item.segmentList.map(item=>{
              return false
            })
        })
         res.code == this.SUCCESS_CODE?
         this.changeFligthList = res.pageResult.pageData:''
         this.searchFlight.totalCount = res.pageResult.totalCount
         this.loading = false
      })
    },
    handleSelect(val,list,type){
      type=='start'?
      list.sAirportCode = val.code:list.eAirportCode = val.code
    },
    // 获取城市接口
    loadAllInternationalCity() {
       internationalCity().then(res => {
        let airList = []
        airList = res.citys.filter(item=>{
            return item.airports==true
        })
        this.internationalAir = airList
      })
    },
    getDetail(text){
      this.detailData = []
      let _this = this
      if(text=='Changing'){
        if(this.supplierType==1){
          findChangeOrder({orderNo :this.orderNo}).then(res=>{
            this.detailData = res.changeDetail
            this.detailData.routeList.map(item=>{
              item.cabinRank = '3'
            })
            passengeShow(res.changeDetail)
            this.passengeList = this.detailData.passengeList
         })
        }else{
          findPkChangeOrder({orderNo:this.orderNo }).then(res=>{
           this.detailData = res.changeDetail
            passengeShow(res.changeDetail)
            this.passengeList = this.detailData.passengeList
         })
        }
      }
      else{
        detail({ orderId: this.orderNo }).then(res => {
        this.detailData = res.data;
        this.passengers = res.data.passengerList;
        this.$forceUpdate();
       })
      }
      function passengeShow(list){
        list.passengeList.map((item,index)=>{
              let _arr = []
              list.relationList.map(relation=>{
                relation.psgIdList.indexOf(item.psgId)!=-1?
                 _arr.push(relation.serialNumber):''
            })
            item.segment = _arr
            item.show = false
            item.showStatus = 0
        })
      }
      scrollTop(300)
    },
    submit(value){
      let type = this.$route.query.type
      switch (type){
        case 'Dishonour':
          this.setDishonour()
          break;
        case 'Changing':
          this.setChanging(value)
          break;
        case 'changPay':
          this.setChangPay()
          break;
        default:
          break;
      }
    },
    //退票
    setDishonour(){
      if(this.checkFlight.length==0)return this.$message({type:"warning",message:"请选择乘客"})
      if(!this.remark)return this.$message({type:"warning",message:"请填写备注"})
      let linId = this.checkFlight.map(item=>{
          return item.airlineId
      })
      let psgId = this.checkFlight.map(item=>{
          return item.psgId
      })
        let data = {
              "lineId": linId.join(','),
              "orderNo": this.detailData.orderNo,
              "psgIds": psgId.join(','),
              "refundOperation": 1,
              "refundType": 1,
              "remark": this.remark
            }
      refund(data).then(res=>{
        console.log(res)
        (this.$message({type:"success",message:"退票成功！"}),this.$router.push({name:'air-ticket-admin-cancel-order'}))
      })
    },
    //废票
    setChangPay(){
       if(this.checkPeopleList.length==0)return this.$message({type:"warning",message:"请选择乘客"})
       if(!this.remark)return this.$message({type:"warning",message:"请填写备注"})
       let psgId = this.checkPeopleList.map(item=>{
              return item.psgId
       })
       let data = {
         orderNo:this.detailData.orderNo,
         passengerId:psgId.join(','),
         remark:this.remark
       }
       invalid(data).then(res=>{
         console.log(res)
         (this.$message({type:"success",message:"废票成功！"}),this.$router.push({name:'air-ticket-admin-cancellation-order'}))
       })
    },
    //改签
    setChanging(value){
       if(this.ticketPassenger.length==0)return this.$message({type:"warning",message:"请选择改签乘客"})
       if(this.ticketFlight.length==0)return this.$message({type:"warning",message:"请选择改签航班"})

      //  this.ticketFlight.every(item=>{
              // if(!item[i]){
              //   this.$message({type:"warning",message:"请完善改签航班信息！"})
              //    return true
              // }
      //  })
      if(this.supplierType==1){
       let passengerList = this.ticketPassenger.map(item=>{
           let obj = {
              "birthday": item.birthday,
              "firstName": item.psgName.split('/')[1],
              "gender": item.gender,
              "lastName": item.psgName.split('/')[0],
              "psgId": item.psgId,
              "psgType":item.psgType
           }
           return obj
       })
       let ticketFlight = this.ticketFlight.map(item=>{
           console.log(item)
           let obj = {
              "airlineCode": item.flightNo.slice(0,2),
              "arrAirportCode": item.eAirportCode,
              "arrDate": item.arriveDate,
              "arrTime": item.arriveTime,
              "cabinCode": item.cabinCode,
              "cabinRank": item.cabinRank,
              "depAirportCode": item.sAirportCode,
              "depDate": item.beginDate,
              "depTime": item.beginTime,
              "flightNo": item.flightNo,
              "routeId": item.airlineIds,
              "supplierRouteId": item.supplierAirlineNo
            }
            return obj
       })
       let data = {
          "changes": ticketFlight,
          "orderNo": this.detailData.orderId,
          "passengerList": passengerList
      }
        orderChange(data).then(res=>{
        res.isSuccess?
        (this.$message({type:"success",message:"改签成功！"}),this.$router.push({name:'air-ticket-admin-change-order'})):''
       })
      }else{
        let passengerList = this.ticketPassenger.map(item=>{
           let obj = {
              "birthday": item.birthday,
              "firstName": item.firstName,
              "gender": item.gender,
              "lastName": item.lastName,
              "psgId": item.psgId,
              "psgType":item.psgType
           }
           return obj
       })
       let ticketFlight = value.segmentList.map((flightItem,flightIndex)=>{
            let routeList = flightItem.routeList.map((item,index)=>{
                   let obj = {
                      "airlineCode": item.airCompanyCode,
                      "arrAirportCode": item.eAirportCode,
                      "arrDate": item.arriveDate,
                      "arrTime": item.arriveTime,
                      "cabinCode": item.cabinCode,
                      "cabinRank": this.ticketFlight[flightIndex].cabinRanks,
                      "depAirportCode": item.sAirportCode,
                      "depDate": item.beginDate,
                      "depTime": item.beginTime,
                      "flightNo": item.flightNo,
                      "routeId": item.routeId,
                      "stopover":item.stopItemList,
                      "planeType":item.planeType,
                      "sTerminal":item.sTerminal,
                      "eTerminal":item.eTerminal,
                      "codeShare":item.codeShare
                      // "supplierRouteId": item.segmentType
                    }
                  return obj
            })
            let _a = {
              "routeList":routeList,
              "segmentId":this.ticketFlight[flightIndex].segmentId,
              "segmentType":this.ticketFlight[flightIndex].segmentType
            }
            return _a
       })
        let data = {
          "orderNo": this.orderNo,
          "passengerList": passengerList,
          "remark": this.applyStatus.text,
          "segmentList":ticketFlight
          }
        // console.log(JSON.stringify(data))
        pkfareChangeRequest(data).then(res=>{
          this.$message({type:"success",message:"改签成功！"})
          this.$router.push({name:'air-ticket-admin-change-order',params:{refresh:1}})
        })
      }
    },
    amend(text){
        this.$router.push({
            query:{ordersNo:this.orderNo,type:text,supplierType:this.$route.query.supplierType}
        })
        this.type = text
        this.getDetail(text)
    },
  },
  created() {
      this.orderNo = this.$route.query.ordersNo
      this.supplierType = this.$route.query.supplierType
      this.type = this.$route.query.type
      this.getDetail(this.type)
      this.loadAllInternationalCity()
  }
};
