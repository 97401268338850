const __request = require(`../__request/__request_contentType_json`);

// 国际机票改签列表
const internationalList = (data) => {

    let pParameter = {
        method: 'post',
        urlSuffix: '/flight/international/buyer/white/order/pkfareChangeRequest',
        data:data
    };

  return __request(pParameter);
};
export default internationalList;


