const __request = require(`../__request/__request_contentType_json`);

// 国际机票后台申请作废
export default (data) => {
let pParameter = {
    method: 'post',
    urlSuffix: '/flight/international/buyer/white/order/invalid',
    data:data
  }

  return __request(pParameter)
}

